import React from "react";
import PropTypes from "prop-types";

import Receipt from "./Receipt";
import ParentDetails from "./ParentDetails";
import ParentChoice from "./ParentChoice";
import AddParentMember from "./AddParentMember";

const payeeOptions = (membership) =>
  membership.members
    .map((mem, index) => [index, mem])
    .filter(
      (mem) => !["Child", "Youth"].includes(mem[1].entity?.membership_type)
    )
    .map((mem) => [
      mem[0],
      `${mem[1].forename} ${mem[1].surname} (${mem[1].email})`,
    ]);

const parentPresent = (membership) =>
  membership.parent_forename ||
  membership.parent_surname ||
  membership.parent_email ||
  membership.parent_mobile;

const noExcludedEntities = (entityRequirements, membership) => {
  const entityIds = membership.members.map((mem) => mem.member_entity_id);
  return (
    (entityRequirements || []).filter(
      (mem) =>
        !mem["excluded_entities"] ||
        (mem["excluded_entities"] &&
          entityIds.filter((n) => !mem["excluded_entities"].includes(n))
            .length > 0)
    ).length > 0
  );
};

const validRequirements = (entityRequirements) =>
  entityRequirements.filter((mem) =>
    mem["type"].split("|").some((value) => ["Full", "Adult"].includes(value))
  ).length > 0;

const newParentMemberRequirement = (membership) =>
  membership.members.filter((mem) => mem.entity?.require_parent_on_membership)
    .length > 0;

const hasParentEntityRequirement = (entityRequirements, membership) =>
  noExcludedEntities(entityRequirements, membership) &&
  validRequirements(entityRequirements);

const ParentOrReceipt = ({
  requireParent,
  membership,
  membershipProduct,
  handlePayeeChange,
  errors,
  handleAddingParent,
  handleParentValueChange,
  handleParentCustomValueChange,
  scrollToAddMember,
}) => {
  const payeeIndex = membership.members.findIndex((mem) => mem.is_payee);

  let parentErrors = {};
  if (membership.addingParent && errors["payee_id"]) {
    parentErrors = {
      parent_forename: ["can't be blank"],
      parent_surname: ["can't be blank"],
      parent_email: ["can't be blank"],
      parent_mobile: ["can't be blank"],
    };
  }
  const entityRequirements =
    membershipProduct.constraints["entity_requirements"];

  const parentMemberRequired = () =>
    hasParentEntityRequirement(entityRequirements, membership) ||
    newParentMemberRequirement(membership);

  const showReceipt =
    payeeIndex > -1 ||
    payeeOptions(membership).length > 0 ||
    (membership.members.length > 0 &&
      hasParentEntityRequirement(entityRequirements, membership));

  const CheckoutUi = () => {
    if (parentMemberRequired() && !showReceipt) {
      return (
        <AddParentMember
          scrollToAddMember={scrollToAddMember}
          errors={errors}
        />
      );
    } else if (showReceipt) {
      return (
        <Receipt
          membership={membership}
          handlePayeeChange={handlePayeeChange}
          errors={errors["payee_id"] || []}
        />
      );
    } else if (
      requireParent || parentPresent(membership) || membership.addingParent
    ) {
      return (
        <ParentDetails
          showSwitchLink={!requireParent}
          membershipProduct={membershipProduct}
          membership={membership}
          errors={{ ...parentErrors, ...errors } || []}
          handleValueChange={handleParentValueChange}
          handleCustomValueChange={handleParentCustomValueChange}
          handleRemoveParent={() => handleAddingParent(true)}
        />
      );
    } else if (
      membership.members.length > 0 &&
      !(parentPresent(membership) || membership.addingParent)
    ) {
      return (
        <ParentChoice
          scrollToAddMember={scrollToAddMember}
          errors={errors}
          handleAddingParent={handleAddingParent}
        />
      );
    } else {
      return (
        <Receipt
          membership={membership}
          handlePayeeChange={handlePayeeChange}
          errors={errors["payee_id"] || []}
        />
      );
    }
  };

  return <div className="member_fields">{CheckoutUi()}</div>;
};

ParentOrReceipt.propTypes = {
  membership: PropTypes.object.isRequired,
  requireParent: PropTypes.bool,
};

export default ParentOrReceipt;
