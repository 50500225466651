import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import axios from "axios";

import {RailsContext} from "../../lib/utils/RailsContext";
import MemberForm from "./Components/MemberForm";
import AddressForm from "./Components/AddressForm";
import LottoEntriesForm from "./Components/LottoEntriesForm";
import MembershipCostBreakdown from "./Components/MembershipCostBreakdown";
import MemberEntitySelector from "./Components/MemberEntitySelector";
import ParentOrReceipt from "./Components/ParentOrReceipt/index.jsx";
import {DynamicField} from "./Components/FormInputs";

import styles from "./Components/Membership.module.scss";

const memberTypeComparison = (a, b) => {
  const memTypeA = a.name.toUpperCase();
  const memTypeB = b.name.toUpperCase();

  let comparison = 0;
  if (memTypeA > memTypeB) {
    comparison = 1;
  } else if (memTypeA < memTypeB) {
    comparison = -1;
  }
  return comparison;
};

const buttonLabel = (productId, clubId) => {
  if (productId === 2387) {
    return "Team";
  } else if (productId === 2385) {
    return "Payment";
  } else if (clubId === 2323) {
    return "Child";
  } else if (clubId === 2393) {
    return "Individual";
  } else {
    return "Member";
  }
}

const AddLink = React.forwardRef(
  ({handleAddMember, existing = false, clubId, productId}, ref) => (
    <div className="add-member-link">
      <button
        ref={ref}
        className="btn btn-primary btn-lg"
        onClick={handleAddMember}
      >
        {existing
          ? `Add Another ${buttonLabel(productId, clubId)}`
          : `Add ${buttonLabel(productId, clubId)}`}
      </button>
    </div>
  )
);

const defaultMemberValues = entity => {
  let baseProps = {
    forename: "",
    surname: "",
    dob: null,
    parental_consent: false,
    emergency_name: "",
    emergency_number: "",
    medical_conditions: "",
    gender: "",
    email: "",
    phone_number: "",
    code_ids: [],
    custom_data: {},
  };
  return baseProps;
};

const defaultMembershipValues = (isAdminForm, drawProduct, lottoMeta) => {
  let baseProps = {
    unconfirmed: true,
    address_1: "",
    address_2: "",
    city: "",
    postcode: "",
    county_id: "",
    country_code: "",
    club_policy_acceptance: "0",
    custom_data: {},
  };
  if (isAdminForm) {
    baseProps["manual_amount"] = 0.0;
    baseProps["notes"] = "";
  }
  if (drawProduct) {
    baseProps["draw_entries"] = {};
    {
      Array.from(Array(drawProduct.entry_count)).map(
        (_, index) =>
          (baseProps["draw_entries"][index] = {
            numbers: new Array(lottoMeta.quantity_drawn),
          })
      );
    }
  }
  return baseProps;
};

const scopedErrors = (errorHash, index, object, attribute) => {
  const key = object.id || index;
  let scopedErrorHash = {};
  if (errorHash && errorHash[attribute] && errorHash[attribute][key]) {
    scopedErrorHash = errorHash[attribute][key];
  }
  return scopedErrorHash;
};

const MembershipCheckoutWithContext = (props, railsContext) => () =>
  (
    <RailsContext.Provider value={railsContext}>
      <MembershipCheckout {...{ ...props }} />
    </RailsContext.Provider>
  );
export default MembershipCheckoutWithContext;

class MembershipCheckout extends React.Component {
  static contextType = RailsContext;

  constructor(props) {
    super(props);
    this.state = {
      membership: {
        ...(props.membership.id
          ? props.membership
          : defaultMembershipValues(
              this.props.isAdminForm,
              props.drawProduct,
              props.lottoMeta
            )),
        membership_product_id: props.membershipProduct.id,
        members: props.membership.id ? this.mappedMembers(props.members) : [],
        members_attributes: {},
      },
      errors: {},
      addingMember: false,
      submitting: false,
    };

    this.addMemberRef = React.createRef();

    this.computeEntity = this.computeEntity.bind(this);
    this.handleAddMember = this.handleAddMember.bind(this);
    this.handleEntitySelected = this.handleEntitySelected.bind(this);
    this.handleEntriesChanged = this.handleEntriesChanged.bind(this);
    this.handleMemberValueChange = this.handleMemberValueChange.bind(this);
    this.handlePayeeChange = this.handlePayeeChange.bind(this);
    this.handleCustomValueChange = this.handleCustomValueChange.bind(this);
    this.handleAccessorValueChange = this.handleAccessorValueChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleCustomDataChange = this.handleCustomDataChange.bind(this);
    this.canAddMember = this.canAddMember.bind(this);
    this.handleMemberTypeChange = this.handleMemberTypeChange.bind(this);
    this.handleAddingParent = this.handleAddingParent.bind(this);
    this.handleRemoveMember = this.handleRemoveMember.bind(this);
    this.multipleEntitiesAvailable = this.multipleEntitiesAvailable.bind(this);
    this.scrollToAddMember = this.scrollToAddMember.bind(this);
    this.lottoEntriesMap = this.lottoEntriesMap.bind(this);
    this.submit = this.submit.bind(this);
  }

  computeEntity(id) {
    let found = this.props.memberEntities.find((ent) => ent.id === id);

    // This is a bodge for the case where a member type is no longer available.
    // found ||= this.props.memberEntities[0]

    return found;
  }

  mappedMembers(members) {
    return members.map((member) => {
      let targetEntity = this.computeEntity(member.member_entity_id);
      member["entity"] = targetEntity;
      return member;
    });
  }

  scrollToAddMember() {
    window.scrollTo({
      left: 0,
      top: this.addMemberRef.current.offsetTop - 150,
      behavior: "smooth",
    });
  }

  handleEntitySelected(id) {
    let targetEntity = this.computeEntity(id);
    let membership = update(this.state.membership, {
      members: {
        $push: [
          {
            member_entity_id: targetEntity.id,
            entity: targetEntity,
            ...defaultMemberValues(targetEntity),
          },
        ],
      },
    });
    this.setState({ membership: membership, addingMember: false });
  }

  handleMemberTypeChange(index, entityId) {
    let targetEntity = this.computeEntity(entityId);

    if (!targetEntity) {
      return;
    }

    let membership = update(this.state.membership, {
      members: {
        [index]: {
          ["entity"]: { $set: targetEntity },
          ["member_entity_id"]: { $set: entityId },
          ["is_payee"]: { $set: false },
        },
      },
    });
    this.setState({ membership: membership });
  }

  handleAddingParent(reverse = false) {
    let membership = this.state.membership;
    let newObj;
    if (reverse) {
      newObj = update(membership, {
        ["addingParent"]: { $set: false },
        ["parent_forename"]: { $set: "" },
        ["parent_surname"]: { $set: "" },
        ["parent_email"]: { $set: "" },
        ["parent_mobile"]: { $set: "" },
      });
    } else {
      newObj = update(membership, {
        ["addingParent"]: { $set: true },
        ["parent_forename"]: { $set: membership.parent_forename || "" },
        ["parent_surname"]: { $set: membership.parent_surname || "" },
        ["parent_email"]: { $set: membership.parent_email || "" },
        ["parent_mobile"]: { $set: membership.parent_mobile || "" },
      });
    }
    this.setState({ membership: newObj });
  }

  handlePayeeChange(payeeIndex) {
    let members = this.state.membership.members.map((mem, index) => {
      if (index === payeeIndex) {
        mem.is_payee = true;
      } else {
        mem.is_payee = false;
      }
      return mem;
    });
    let membership = update(this.state.membership, {
      ["members"]: { $set: members },
    });
    this.setState({ membership: membership });
  }

  handleAddMember() {
    this.setState({ addingMember: true });
  }

  async handleRemoveMember(index, member) {
    if (member.id) {
      try {
        await axios({
          method: "delete",
          url: `/api/members/${member.id}?token=${member.validation_token}`,
          data: JSON.stringify({ membership_id: this.props.membership.id }),
          headers: {
            Accept: "application/json;version=3",
            "X-ClubZap-App-Id": "ClubZapWebsite",
            "Content-Type": "application/json",
          },
        });
        let membership = update(this.state.membership, {
          members: { $splice: [[index, 1]] },
        });
        this.setState({ membership: membership });
      } catch (error) {
        console.error(
          `/public/memberships/${this.props.membership.id}`,
          error.toString()
        );
        if (this.state.membership.members.length == 1) {
          alert("Error! Cannot remove the last member on the membership.");
        } else {
          alert("Error! Unable to remove member. Please reload the page.");
        }
      }
    } else {
      let membership = update(this.state.membership, {
        members: { $splice: [[index, 1]] },
      });
      this.setState({ membership: membership });
    }
  }

  canAddMember() {
    return !this.state.addingMember && (this.state.membership.unconfirmed || this.props.isAdminForm);
  }

  multipleEntitiesAvailable() {
    return true;
  }

  handleAccessorValueChange(index, field, value) {
    let membership = update(this.state.membership, {
      members: { [index]: { ["custom_data"]: { [field]: { $set: value } } } },
    });
    this.setState({ membership: membership });
  }

  handleCustomValueChange(index, field, value) {
    let membership = update(this.state.membership, {
      members: {
        [index]: { ["custom_data"]: { [field]: { $set: value } } },
      },
    });
    this.setState({ membership: membership });
  }

  handleMemberValueChange(index, field, value) {
    let membership = update(this.state.membership, {
      members: {
        [index]: { [field]: { $set: value } },
      },
    });
    this.setState({ membership: membership });
  }

  handleEntriesChanged(index, entry) {
    let membership = update(this.state.membership, {
      draw_entries: { [index]: { $set: entry } },
    });
    this.setState({ membership });
  }

  handleValueChange(field, value) {
    const newObj = update(this.state.membership, { [field]: { $set: value } });
    this.setState({ membership: newObj });
  }

  handleCustomDataChange(field, value) {
    const newObj = update(this.state.membership, {
      ["custom_data"]: {
        [field]: { $set: value },
      },
    });
    this.setState({ membership: newObj });
  }

  lottoEntriesMap() {
    const entries = this.state.membership.draw_entries;
    if (!entries) {
      return {};
    }
    return Object.keys(entries).map((key) => ({
      ...entries[key],
      ...{ updated_at: new Date().toUTCString() },
    }));
  }

  submit() {
    this.setState({ submitting: true });
    let url;
    if (this.props.isAdminForm) {
      url = this.props.membership.id
        ? `/memberships/${this.props.membership.id}`
        : `/clubs/${this.props.membershipProduct.club_id}/memberships`;
    } else {
      url = this.props.membership.id
        ? `/public/memberships/${this.props.membership.id}`
        : `/public/clubs/${this.props.membershipProduct.club_id}/memberships`;
    }

    let membership = update(this.state.membership, {
      members_attributes: {
        $set: Object.assign(
          {},
          this.state.membership.members.map((member) => ({
            ...member,
            ...{ updated_at: new Date().toUTCString() },
          }))
        ),
      },
      draw_entries_attributes: {
        $set: Object.assign({}, this.lottoEntriesMap()),
      },
    });

    this.setState({ membership: membership }, () => {
      $.ajax({
        url: url,
        contentType: "application/json",
        type: this.props.membership.id ? "PATCH" : "POST",
        data: JSON.stringify({ membership: this.state.membership }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-ClubZap-App-Id": "ClubZapWebsite",
        },
        success: (data) => {
          if (!this.props.isAdminForm) {
            sessionStorage.setItem("membership_id", data.membership_id);
          }
          window.location.replace(data.redirect);
        },
        error: (xhr, status, err) => {
          this.setState({ submitting: false, errors: xhr.responseJSON || {} });
        },
      });
    });
  }

  render() {
    const {
      isAdminForm,
      membership,
      membershipProduct,
      drawProduct,
      lottoMeta,
      membershipCustomFields,
      memberEntities,
      codes,
      counties,
      countries,
      genderOptions,
    } = this.props;
    return (
      <div className="multi_input">
        {this.state.membership.members.map((member, index) => (
          <MemberForm
            index={index}
            isAdminForm={isAdminForm}
            member={member}
            membership={membership}
            memberTypes={[...memberEntities]
              .sort(memberTypeComparison)
              .map((entity) => [entity.id, entity.name])}
            membershipProduct={membershipProduct}
            genderOptions={genderOptions}
            codes={codes}
            key={index.toString()}
            handleRemoveMember={this.handleRemoveMember}
            handleMemberTypeChange={this.handleMemberTypeChange}
            handleValueChange={this.handleMemberValueChange}
            handleCustomValueChange={this.handleCustomValueChange}
            handleAccessorValueChange={this.handleAccessorValueChange}
            errors={scopedErrors(this.state.errors, index, member, "members")}
          />
        ))}

        {this.state.addingMember && this.multipleEntitiesAvailable && (
          <MemberEntitySelector
            types={memberEntities.map((entity) => ({
              id: entity.id,
              name: entity.name,
            }))}
            handleEntitySelected={this.handleEntitySelected}
            cancelSelection={() => this.setState({ addingMember: false })}
          />
        )}

        <div className="add_member_link">
          {this.canAddMember() ? (
            <AddLink
              handleAddMember={this.handleAddMember}
              existing={
                this.state.membership.members &&
                this.state.membership.members.length > 0
              }
              productId={membershipProduct.id}
              clubId={membershipProduct.club_id}
              ref={this.addMemberRef}
            />
          ) : (
            ""
          )}
        </div>

        <MembershipCostBreakdown
          memberEntities={this.props.memberEntities}
          members={this.state.membership.members}
          product={this.props.membershipProduct}
          clubId={membershipProduct.club_id}
          showBreakdown={this.props.showBreakdown}
        />

        {drawProduct && (
          <LottoEntriesForm
            drawProduct={drawProduct}
            lottoMeta={lottoMeta}
            drawEntries={this.state.membership.draw_entries}
            handleEntriesChanged={this.handleEntriesChanged}
            errors={this.state.errors["draw_entries"]}
          />
        )}

        <AddressForm
          membership={this.state.membership}
          customFields={membershipCustomFields}
          membershipProduct={this.props.membershipProduct}
          handleValueChange={this.handleValueChange}
          handleCustomDataChange={this.handleCustomDataChange}
          countries={countries}
          counties={counties}
          errors={this.state.errors}
        />

        {this.state.membership.members.length > 0 && (
          <ParentOrReceipt
            requireParent={this.props.requireParent}
            membership={this.state.membership}
            membershipProduct={this.props.membershipProduct}
            handlePayeeChange={this.handlePayeeChange}
            errors={this.state.errors || []}
            handleAddingParent={this.handleAddingParent}
            handleParentValueChange={this.handleValueChange}
            handleParentCustomValueChange={this.handleCustomDataChange}
            scrollToAddMember={this.scrollToAddMember}
          />
        )}

        {this.props.isAdminForm && (
          <div className="member_fields">
            <DynamicField
              name="manual_amount"
              label="Amount Paid (Manually)"
              value={this.state.membership["manual_amount"]}
              error={
                this.state.errors["manual_amount"]
                  ? this.state.errors["manual_amount"][0]
                  : ""
              }
              isRequired={false}
              handleOnChange={(val) =>
                this.handleValueChange("manual_amount", val)
              }
            />
          </div>
        )}

        {this.props.isAdminForm && (
          <div className="member_fields">
            <DynamicField
              type="boolean"
              name="manual_amount_complete"
              label="Manual Amount Complete?"
              value={this.state.membership["manual_amount_complete"]}
              error={
                this.state.errors["manual_amount_complete"]
                  ? this.state.errors["manual_amount_complete"][0]
                  : ""
              }
              handleOnChange={(val) =>
                this.handleValueChange("manual_amount_complete", val)
              }
            />
          </div>
        )}

        {this.props.isAdminForm &&
          this.props.membership["state"] === "pending_renewal" && (
            <div className="member_fields">
              <DynamicField
                type="boolean"
                name="skip_renewals"
                label="Send Renewal Emails"
                value={!this.state.membership["skip_renewals"]}
                error={
                  this.state.errors["skip_renewals"]
                    ? this.state.errors["skip_renewals"][0]
                    : ""
                }
                handleOnChange={(val) =>
                  this.handleValueChange("skip_renewals", !val)
                }
              />
            </div>
          )}

        {this.props.isAdminForm && (
          <div className="member_fields">
            <DynamicField
              name="notes"
              type="textarea"
              label="Notes (Visible to Admins only)"
              value={this.state.membership["notes"]}
              error={
                this.state.errors["notes"] ? this.state.errors["notes"][0] : ""
              }
              isRequired={false}
              handleOnChange={(val) => this.handleValueChange("notes", val)}
            />
          </div>
        )}

        {Object.keys(this.state.errors).length > 0 && (
          <div className={styles.baseError}>
            {this.state.errors.base || "Please review the errors above"}
          </div>
        )}

        <button
          onClick={this.submit}
          className="btn btn-primary"
          disabled={this.state.submitting}
        >
          {this.state.submitting ? "Submitting" : "Continue"}
        </button>
      </div>
    );
  }
}

MembershipCheckout.propTypes = {
  membership: PropTypes.object.isRequired,
  membershipCustomFields: PropTypes.arrayOf(PropTypes.object),
  members: PropTypes.arrayOf(PropTypes.object),
  membershipProduct: PropTypes.object.isRequired,
  drawProduct: PropTypes.object,
  lottoMeta: PropTypes.object,
  memberEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  countries: PropTypes.array.isRequired,
  counties: PropTypes.object.isRequired,
  codes: PropTypes.arrayOf(PropTypes.array),
  showBreakdown: PropTypes.bool.isRequired,
  isAdminForm: PropTypes.bool,
  genderOptions: PropTypes.arrayOf(PropTypes.array),
};
